import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunkMiddleware, { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { reducer as reduxFormReducer } from 'redux-form'
import {
  loaderReducer,
  tournamentListReducer,
  gameListReducer,
  gameDetailsReducer,
  tournamentDetailsReducer,
  spaceListReducer,
  moderatorListReducer,
  userAccessRequestListReducer,
  spaceDetailsReducer,
  spacePostListReducer,
  rivalryZoneListReducer,
  rivalryZoneDetailsReducer,
  spaceProductsReducer,
  redeemRequestReducer,
  withdrawalRequestReducer,
  bannerListReducer,
  categoryListReducer,
  productListReducer,
  productRedeemRequestReducer,
  dashboardDetailsReducer,
  categoryProductsReducer,
  userDetailsReducer,
  bannerDetailsReducer,
  userAccessGameRequestListReducer,
  spacePostDetailsReducer,
  campaignLinkReducer,
  productDetailsReducer,
  shortsListReducer,
  pinnedSpacePostListReducer,
  pinnedTournamentListReducer,
  provisionalTournamentListReducer,
  provisionalTournamentDetailsReducer,
  spaceShortsListReducer,
  spaceShortsDetailsReducer,
  spaceShortsCommentsReducer,
  couponListReducer,
  gameStoreListReducer,
  gameStoreDetailsReducer,
  userAccessGameStoreRequestListReducer,
  proPassUsersListReducer,
  creatorListReducer,
  CreatorDetailsReducer,
  toggleThemeReducer,
  enableToggleSwitchReducer,
  spaceSubscriptionReducer,
  spaceSubscriptionGraphReducer,
  challengeDetailsReducer,
  challengeListReducer,
  userWalletDetailsReducer,
  pinnedProductCategoryReducer,
} from '../redux/reducer'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { Action } from 'core/models/ActionTypes'

const reducer = combineReducers({
  form: reduxFormReducer,
  loader: loaderReducer,
  tournaments: tournamentListReducer,
  games: gameListReducer,
  game: gameDetailsReducer,
  tournament: tournamentDetailsReducer,
  spaces: spaceListReducer,
  moderators: moderatorListReducer,
  userAccessRequests: userAccessRequestListReducer,
  space: spaceDetailsReducer,
  spacePosts: spacePostListReducer,
  spacePost: spacePostDetailsReducer,
  rivalryZones: rivalryZoneListReducer,
  rivalryZone: rivalryZoneDetailsReducer,
  spaceProducts: spaceProductsReducer,
  withdrawalRequests: withdrawalRequestReducer,
  redeemRequests: redeemRequestReducer,
  banners: bannerListReducer,
  banner: bannerDetailsReducer,
  productCategories: categoryListReducer,
  products: productListReducer,
  productRedeemRequests: productRedeemRequestReducer,
  dashboardDetails: dashboardDetailsReducer,
  product: productDetailsReducer,
  categoryProducts: categoryProductsReducer,
  userDetails: userDetailsReducer,
  userAccessGameRequests: userAccessGameRequestListReducer,
  campaignLink: campaignLinkReducer,
  shorts: shortsListReducer,
  pinnedTournaments: pinnedTournamentListReducer,
  pinnedSpacePosts: pinnedSpacePostListReducer,
  provisionalTournaments: provisionalTournamentListReducer,
  provisionalTournament: provisionalTournamentDetailsReducer,
  spaceShorts: spaceShortsListReducer,
  spaceShort: spaceShortsDetailsReducer,
  shortsComments: spaceShortsCommentsReducer,
  coupons: couponListReducer,
  gameStores: gameStoreListReducer,
  gameStore: gameStoreDetailsReducer,
  gameStoreRequests: userAccessGameStoreRequestListReducer,
  proPassUsersList: proPassUsersListReducer,
  creatorList: creatorListReducer,
  creator: CreatorDetailsReducer,
  toggleTheme: toggleThemeReducer,
  isSwitchable: enableToggleSwitchReducer,
  spaceSubscription: spaceSubscriptionReducer,
  spaceSubscriptionGraph: spaceSubscriptionGraphReducer,
  challenges: challengeListReducer,
  challenge: challengeDetailsReducer,
  wallet: userWalletDetailsReducer,
  pinnedProductCategories: pinnedProductCategoryReducer,
})

const middlewares = [thunkMiddleware]

const store = createStore(reducer, applyMiddleware(...middlewares))

export default store

export type Store = ReturnType<typeof reducer>
export type TypedDispatch = ThunkDispatch<Store, any, Action>;
export type TypedThunk<ReturnType = any> = ThunkAction<ReturnType, Store, unknown, Action>;

export const useTypedDispatch = () => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<Store> = useSelector;